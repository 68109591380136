import { Component, inject, ViewChild } from '@angular/core';
import { Actions, ofActionDispatched } from '@ngxs/store';
import { IonModal, Platform } from '@ionic/angular';
import { AppActions } from './app.actions';
import { NetworkService } from './shared/services/network.service';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { SyncService } from './shared/services/sync.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  @ViewChild('offlineWarningModal')
  offlineWarningModal: IonModal;

  private _platform = inject(Platform);
  private _actions$ = inject(Actions);
  private _networkService = inject(NetworkService);
  private _syncService = inject(SyncService);

  constructor() {
    this._platform
        .ready()
        .then(() => {
          this._listenToFirsRunError();
          this._syncData();
        });
  }

  private _listenToFirsRunError() {
    this._actions$
        .pipe(
            ofActionDispatched(AppActions.NeedsFirstRunOnline),
        )
        .subscribe({
          next: () => {
            this.offlineWarningModal.present();
          },
        });
  }

  private _syncData() {
    this._networkService
        .getStatusFromState()
        .pipe(
            distinctUntilChanged((previous, current) => {
              return previous.connected === current.connected;
            }),
            filter((status) => status.connected),
        )
        .subscribe({
          next: () => {
            this._syncService.startSync();
          },
        });
  }
}

import { FormGroup } from '@angular/forms';
import { SurveyForm } from './survey.page';

export class SurveyPayloadModel {
  city_id: number;
  satisfaction: number;
  recommend: number;
  improve_experience: string;
  topic_suggestion: string;

  constructor(surveyFormFormGroup: FormGroup<SurveyForm>) {
    const surveyFormValue = surveyFormFormGroup.getRawValue();

    this.city_id = surveyFormValue.operation ?? 0;
    this.satisfaction = surveyFormValue.satisfaction ?? 0;
    this.recommend = surveyFormValue.recommend ?? 0;
    this.improve_experience = surveyFormValue.improveExperience;
    this.topic_suggestion = surveyFormValue.topicSuggestion;
  }
}

import { inject, Injectable } from '@angular/core';
import { ApiService } from '../shared/services/api.service';
import { ParticipantResponseModel } from './participant-response.model';
import { ParticipantModel } from './participant.model';
import { catchError, map, switchMap } from 'rxjs/operators';
import { RsvpPayloadModel } from '../rsvp/models/rsvp-payload.model';
import { NetworkService } from '../shared/services/network.service';
import { SyncService } from '../shared/services/sync.service';
import { Observable } from 'rxjs';

@Injectable()
export class ParticipantsService {

  static apiPath = 'reservations';

  private _apiService = inject(ApiService);
  private _networkService = inject(NetworkService);
  private _syncService = inject(SyncService);

  getParticipants$() {
    return this._apiService
               .get<ParticipantResponseModel[]>(ParticipantsService.apiPath)
               .pipe(
                   map((participantsResponse: ParticipantResponseModel[]) =>
                       participantsResponse.map((participantResponse) =>
                           new ParticipantModel(participantResponse)),
                   ),
                   catchError((error) => {
                     console.log('error', error);
                     throw error;
                   }),
               );
  }

  createParticipant$(payload: RsvpPayloadModel): Observable<ParticipantModel> {
    const post$ = this._apiService
                      .post<ParticipantResponseModel>(
                          ParticipantsService.apiPath,
                          payload,
                          true,
                      );
    const addParticipantToCreateInSync$ =
        this._syncService.setParticipantToCreate(payload);

    return this._networkService
               .getStatusFromState()
               .pipe(
                   switchMap((status) => {
                     if (status.connected) {
                       return post$;
                     }
                     return addParticipantToCreateInSync$;
                   }),
                   map((participantResponseModel: ParticipantResponseModel) =>
                       new ParticipantModel(participantResponseModel),
                   ),
                   catchError((error) => {
                     console.log('error', error);
                     throw error;
                   }),
               );
  }

  updateParticipant$(
      id: string,
      payload: RsvpPayloadModel,
  ): Observable<ParticipantModel> {

    const put$ = this._apiService
                     .put<ParticipantResponseModel>(
                         `${ ParticipantsService.apiPath }/${ id }`,
                         payload,
                         true,
                     );

    const addParticipantToUpdateInSync$ =
        this._syncService.setParticipantToUpdate({ id, payload });

    return this._networkService
               .getStatusFromState()
               .pipe(
                   switchMap((status) => {
                     if (status.connected) {
                       return put$;
                     }
                     return addParticipantToUpdateInSync$;
                   }),
                   map((participantResponseModel: ParticipantResponseModel) =>
                       new ParticipantModel(participantResponseModel),
                   ),
                   catchError((error) => {
                     console.log('error', error);
                     throw error;
                   }),
               );
  }
}

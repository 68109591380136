import {
  RsvpForm,
} from '../../shared/components/rsvp-form/rsvp-form.component';
import { FormGroup } from '@angular/forms';

export class RsvpPayloadModel {
  city_id: number; // Praça
  name: string; // 'Nome Junior',
  nickname: string; // 'Nome do crachá',
  city_state: string; // 'Cidade - SP',
  phone: string; // '(17) 3353-2444',
  company: string; // 'FMD',
  profession: string; // 'Desenvolvedor',
  property: string; // '1.000 m²'

  /**
   * reserved: true - Participante preencheu o formulário de inscrição
   * accredited: false - Participante não retirou o crachá
   * */
  constructor(
      rsvpFormGroup: FormGroup<RsvpForm>,
      public reserved = false,
      public accredited = false,
  ) {

    const rsvpFormValue = rsvpFormGroup.getRawValue();

    this.city_id = Number(rsvpFormValue.operation);
    this.name = rsvpFormValue.name;
    this.nickname = rsvpFormValue.badgeName;
    this.city_state = rsvpFormValue.city;
    this.phone = rsvpFormValue.phone;
    this.company = rsvpFormValue.company;
    this.profession = rsvpFormValue.profession;
    this.property = rsvpFormValue.propertySize;
  }
}

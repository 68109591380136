import { inject, Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { LoadingOptions } from '@ionic/core';
import { defer, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoadingIndicatorService {

  private _loadingController = inject(LoadingController);

  private activeLoading: HTMLIonLoadingElement;
  private _isPresenting = false;
  private _loadingCount = 0;

  dismiss(): void {

    this._loadingCount--;

    if (!this._isPresenting) {
      return;
    }

    if (this._hasPendingLoadings()) {
      return;
    }

    this.activeLoading
        .dismiss()
        .then(() => this._isPresenting = false);
  }

  present(loadingOptions?: LoadingOptions): Observable<boolean> {

    this._loadingCount++;

    if (this._isPresenting) {
      return of(false);
    }

    return defer(async () => {
      this._isPresenting = true;
      this.activeLoading = await this._loadingController
                                     .create({
                                       ...loadingOptions,
                                       translucent: true,
                                       spinner: 'crescent',
                                     });
      await this.activeLoading.present();
      return this._isPresenting
    });
  }

  private _hasPendingLoadings(): boolean {
    return this._loadingCount > 0;
  }
}

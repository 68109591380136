<ion-grid>
  <ion-row class="ion-justify-content-between">
    <ion-col *ngIf="!!title"
             class="ion-no-padding"
             size="12"
             size-xl="4">
      <h3 class="fs-normal">
        {{ title }}
      </h3>
    </ion-col>
    <ion-col [attr.size-xl]="!!title ? '8' : '12'"
             class="ion-no-padding">
      <ng-content></ng-content>
    </ion-col>
  </ion-row>
</ion-grid>

<div class="divisor"></div>

import { inject, Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import {
  Entity,
  StateHelpers,
  StateModel,
} from '../shared/store/state-helpers';
import { StoreActions } from '../shared/store/store.actions';
import { OperationsService } from './operations.service';
import { OperationsActions } from './operations.actions';
import { OperationModel } from './operation.model';

export type OperationsEntity = Entity<OperationModel>;
export type OperationsStateModel = StateModel<OperationsEntity>;

const OPERATIONS_STATE_TOKEN = new StateToken<OperationsStateModel>(
    'OperationsState');

const defaults: OperationsStateModel = StateHelpers.getDefaultState();

@State<OperationsStateModel>({
  name: OPERATIONS_STATE_TOKEN,
  defaults,
})
@Injectable()
export class OperationsState {

  private _participantsService = inject(OperationsService);

  @Selector()
  static hasError(state: OperationsStateModel): boolean {
    return state.hasError;
  }

  @Selector()
  static isLoaded(state: OperationsStateModel): boolean {
    return state.isLoaded;
  }

  @Selector()
  static getOperations(state: OperationsStateModel): OperationModel[] {
    return StateHelpers.mapIdsListToEntitiesList(state.ids, state.entities);
  }

  @Action(OperationsActions.Get)
  getOperations(ctx: StateContext<OperationsStateModel>) {
    return this._participantsService
               .getOperations$()
               .subscribe({
                 next: (participants) => {

                   const newState = {
                     entities: StateHelpers.reduceListToEntities(participants),
                     ids: StateHelpers.mapEntitiesListToIdsList(participants),
                     ...StateHelpers.getApiLoadSuccessConfig(),
                   };

                   return ctx.dispatch(new OperationsActions.GetSuccess(
                       newState));
                 },
                 error: (error) => {
                   return ctx.dispatch(new OperationsActions.GetFailed(error));
                 },
               });
  }

  @Action(OperationsActions.GetSuccess)
  getSuccess(
      ctx: StateContext<OperationsStateModel>,
      action: OperationsActions.GetSuccess,
  ) {
    ctx.patchState(action.payload);
  }

  @Action(OperationsActions.GetFailed)
  getFailed(ctx: StateContext<OperationsStateModel>) {
    ctx.patchState(StateHelpers.getApiLoadErrorConfig());
  }

  @Action(OperationsActions.ResetLoadedState)
  resetLoadedState(ctx: StateContext<OperationsStateModel>) {
    ctx.patchState({
      isLoaded: defaults.isLoaded,
      hasError: defaults.hasError,
    });
  }

  @Action([
    OperationsActions.ResetState,
    StoreActions.ResetStore,
  ])
  resetState(ctx: StateContext<OperationsStateModel>) {
    ctx.patchState({
      ...defaults,
    });
  }
}

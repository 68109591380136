import { CapacitorConfig } from '@capacitor/cli';

const config: CapacitorConfig = {
  appId: 'br.tec.xlab.bayer.circuito.i2x',
  appName: 'xlab-bayer-circuito-i2x-app',
  webDir: 'www',
  server: {
    androidScheme: 'https'
  }
};

export default config;

import {
  Component,
  ElementRef,
  inject,
  TrackByFunction,
  ViewChild,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  NonNullableFormBuilder,
  Validators,
} from '@angular/forms';
import { environment } from '../../../../environments/environment';
import {
  CustomValidators,
  namePattern,
} from '../../directives/form-custom-validators';
import { OperationModel } from '../../../operations/operation.model';
import { MaskitoElementPredicate, MaskitoOptions } from '@maskito/core';
import { maskitoWithPlaceholder } from '@maskito/kit';
import { Actions, ofActionDispatched, Select, Store } from '@ngxs/store';
import {
  ParticipantsActions,
} from '../../../participants/participants.actions';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router } from '@angular/router';
import { RoutingService } from '../../services/routing.service';
import { ParticipantsState } from '../../../participants/participants.state';
import { OperationsState } from '../../../operations/operations.state';
import { Observable } from 'rxjs';
import { ParticipantModel } from '../../../participants/participant.model';
import { ToastService } from '../../services/toast.service';
import { filter } from 'rxjs/operators';

export interface RsvpForm {
  operation: FormControl<number>;
  name: FormControl<string>;
  city: FormControl<string>;
  company: FormControl<string>;
  phone: FormControl<string>;
  profession: FormControl<string>;
  propertySize: FormControl<string>;
  badgeName: FormControl<string>;
  privacyPolicy: FormControl<boolean>;
}

export interface RsvpFormSearch {
  searchTerm: FormControl<string>;
}

@Component({
  selector: 'app-rsvp-form',
  templateUrl: './rsvp-form.component.html',
  styleUrls: ['./rsvp-form.component.scss'],
})
export class RsvpFormComponent {

  @ViewChild('rsvpForm', { static: true })
  rsvpFormElementRef: ElementRef<HTMLFormElement>;

  @Select(ParticipantsState.getFoundParticipant)
  getFoundParticipants$: Observable<ParticipantModel[]>;

  @Select(ParticipantsState.isEditingParticipant)
  isRegisteringParticipant$: Observable<boolean>;

  @Select(ParticipantsState.getEditingParticipant)
  getEditingParticipant$: Observable<ParticipantModel>;

  @Select(OperationsState.getOperations)
  operations$: Observable<OperationModel[]>;

  private _actions$ = inject(Actions);
  private _router = inject(Router);
  private _activatedRoute = inject(ActivatedRoute);
  private _store = inject(Store);
  private _nonNullableFormBuilder = inject(NonNullableFormBuilder);
  private _toastService = inject(ToastService);
  routingService = inject(RoutingService);

  trackByFn: TrackByFunction<ParticipantModel> = (index, item) => {
    return item.id;
  };

  readonly maskPredicate: MaskitoElementPredicate = async (el) => (el as HTMLIonInputElement).getInputElement();
  maskitoCellphone = {
    ...maskitoWithPlaceholder('() _____-____'),
    mask: [
      '(',
      /\d/,
      /\d/,
      ')',
      ' ',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ],
    overwriteMode: 'replace',
  } as MaskitoOptions;

  rsvpFormGroup: FormGroup<RsvpForm> = this._nonNullableFormBuilder.group({
    operation: this._nonNullableFormBuilder.control(
        (!environment.production) ? 16 : 0,
        [Validators.min(1)],
    ),
    name: this._nonNullableFormBuilder.control(
        (!environment.production) ? 'Teste F&MD' : '',
        [Validators.required, Validators.pattern(namePattern)],
    ),
    city: this._nonNullableFormBuilder.control(
        (!environment.production) ? 'São José do Rio Preto - SP' : '',
        [Validators.required],
    ),
    phone: this._nonNullableFormBuilder.control(
        (!environment.production) ? '17977756478' : '',
        [
          Validators.required,
          Validators.minLength(11),
          CustomValidators.Phone(),
        ],
    ),
    company: this._nonNullableFormBuilder.control(
        (!environment.production) ? 'Agência F&MD' : '',
    ),
    profession: this._nonNullableFormBuilder.control(
        (!environment.production) ? 'Desenvolvedor' : '',
        [Validators.required],
    ),
    propertySize: this._nonNullableFormBuilder.control(
        (!environment.production) ? '10000' : '',
    ),
    badgeName: this._nonNullableFormBuilder.control(
        (!environment.production) ? 'testerF&MD' : '',
        [Validators.required],
    ),
    privacyPolicy: this._nonNullableFormBuilder.control(
        this.routingService.isRegister(),
        [Validators.requiredTrue],
    ),
  });

  searchFormGroup: FormGroup<RsvpFormSearch> =
      this._nonNullableFormBuilder.group(
          {
            searchTerm: this._nonNullableFormBuilder.control(''),
          });

  constructor() {
    this._listenForCreateOrRegisterParticipant();
    this._listenFormCreateOrRegisterParticipantFailure();
    this._listenSearchTermChanges();
    this._listenForEditingParticipant();
  }

  editParticipant(participant: ParticipantModel) {
    this._store
        .dispatch(new ParticipantsActions.Editing.Set(participant))
        .subscribe({
          next: () => {
            this._store.dispatch(new ParticipantsActions.Search.Reset());
            this.searchFormGroup.patchValue({
              searchTerm: '',
            });
          },
        });
  }

  onSubmit() {

    if (this.rsvpFormGroup.invalid) {
      return;
    }

    this._store.dispatch(new ParticipantsActions.Form.Submit(this.rsvpFormGroup));
  }

  shouldShowSearchResults() {
    return this.searchFormGroup.controls.searchTerm.value.length > 2;
  }

  shouldDisableResultParticipant(participant: ParticipantModel): boolean {
    if (participant.reserved && this.routingService.isRsvp()) {
      return true;
    }

    return participant.accredited && this.routingService.isRegister();
  }

  getBoundingClientRect(): DOMRect {
    return this.rsvpFormElementRef.nativeElement.getBoundingClientRect();
  }

  reset() {
    this.rsvpFormGroup.reset();
    this.rsvpFormGroup.enable();
    this._store.dispatch(new ParticipantsActions.Search.Reset());
    this._store.dispatch(new ParticipantsActions.Editing.Reset());
  }

  private _listenForCreateOrRegisterParticipant() {
    this._actions$
        .pipe(
            ofActionDispatched(
                ParticipantsActions.CreateSuccess,
                ParticipantsActions.RegisterSuccess,
            ),
            takeUntilDestroyed(),
        )
        .subscribe({
          next: () => {
            this._router
                .navigate(
                    [this.routingService.routes.feedback.path],
                    { relativeTo: this._activatedRoute },
                )
                .then(() => {
                  this.rsvpFormGroup.reset();
                  this._store.dispatch(new ParticipantsActions.Editing.Reset());
                });
          },
        });
  }

  private _listenFormCreateOrRegisterParticipantFailure() {
    this._actions$
        .pipe(
            ofActionDispatched(
                ParticipantsActions.CreateFailed,
                ParticipantsActions.RegisterFailed,
            ),
            takeUntilDestroyed(),
        )
        .subscribe({
          next: (error) => {
            this._toastService.present({ message: error.reason.errors.phone[0] });
          },
        });
  }

  private _listenForEditingParticipant() {

    this.getEditingParticipant$
        .pipe(
            filter((participant) => !!Object.keys(participant).length),
            takeUntilDestroyed(),
        )
        .subscribe({
          next: (participant) => {

            if (!participant) {
              this.rsvpFormGroup.enable();
              return;
            }

            this.rsvpFormGroup
                .patchValue({
                  ...participant,
                  operation: participant.cityId,
                  city: participant.cityState,
                  propertySize: participant.property,
                  badgeName: participant.nickname,
                });

            this._disableFormOnRegisterFilled();
          },
        });
  }

  private _listenSearchTermChanges(): void {
    this.searchFormGroup.controls.searchTerm.valueChanges
        .pipe(
            takeUntilDestroyed(),
        )
        .subscribe({
          next: (searchTerm) => {
            this._store.dispatch(new ParticipantsActions.Search.Term(searchTerm));
          },
        });
  }

  private _disableFormOnRegisterFilled() {
    if (!this.routingService.isRegister()) {
      return;
    }
    this.rsvpFormGroup.disable();
  }
}

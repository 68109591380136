<ion-app>
  <ion-router-outlet></ion-router-outlet>
</ion-app>

<ion-modal #offlineWarningModal
           [canDismiss]="true"
           class="modal-dialog">
  <ng-template>
    <div class="ion-padding">
      Você precisa estar online para o primeiro acesso!
    </div>
  </ng-template>
</ion-modal>


import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable()
export class MockingService {

  forms = {
    login: {
      document: (!environment.production) ? '12345678909' : '',
      password: (!environment.production) ? 'M@m10203040' : '',
    },
    register: {
      document: (!environment.production) ? '12345678909' : '',
      password: (!environment.production) ? 'M@m10203040' : '',
      privacyPolicy: !environment.production,
    }
  };
}

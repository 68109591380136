import { inject, Injectable } from '@angular/core';
import { ConnectionStatus, Network } from '@capacitor/network';
import { Select, Store } from '@ngxs/store';
import { AppActions } from '../../app.actions';
import { ToastService } from './toast.service';
import { AppState } from '../../app.state';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NetworkService {

  @Select(AppState.getNetworkStatus)
  networkStatus$: Observable<ConnectionStatus>;

  private _store = inject(Store);
  private _toastService = inject(ToastService);

  constructor() {
    Network.addListener('networkStatusChange', (status) => {
      this._store.dispatch(new AppActions.NetworkStatusChanged(status));
      console.log('Network status changed', status);
    });
  }

  async getStatus(): Promise<ConnectionStatus> {
    return await Network.getStatus();
  }

  getStatusFromState(): Observable<ConnectionStatus> {
    return this.networkStatus$;
  }

  showOfflineFeedback(message: string = 'Você está offline. Por favor, conecte-se a internet para continuar!') {
    this._toastService.present({ message });
  }
}

export interface IdentifiedObject {
  id: number | string;
}

export interface Entity<T> {
  [id: string]: T;
}

export interface StateModel<T> {
  entities: T;
  ids: string[];
  isLoaded: boolean;
  hasError: boolean;
}

export class StateHelpers {

  /**
   * @param list - List to be converted to Entities Object
   *
   * It's important to notice that the source list must be of Identified
   * Objects, which means that each item must have an id property
   * */
  static reduceListToEntities<T extends IdentifiedObject>(list: T[]): Entity<T> {

    return list.reduce((prev, current) => {
      return {
        ...prev,
        [current.id]: current,
      };
    }, {} as Entity<T>);
  }

  /**
   * @param idsList - State entities id
   * @param entities - State entities
   * */
  static mapIdsListToEntitiesList<T>(
      idsList: string[], entities: Entity<T>,
  ): T[] {

    return idsList.map((id) => entities[id]);
  }

  /**
   * @param entitiesList - Identified Objects List
   *
   * It's important to notice that the source list must be a list of Identified
   * Objects, which means that it must have an id property
   * */
  static mapEntitiesListToIdsList<T extends IdentifiedObject>(
      entitiesList: T[],
  ): string[] {

    return entitiesList.map((object) => {

      if (typeof object.id === 'string') {
        return object.id;
      }

      return String(object.id);
    });
  }

  /**
   * @param stateEntities - State entities
   * @param itemsToRemove - Items to remove from state entities
   *
   * @return Returns an Object of filtered entities, without the undesired items
   * */
  static filterEntities<T extends IdentifiedObject>(
      stateEntities: Entity<T>,
      itemsToRemove: T[],
  ): Entity<T> {

    return itemsToRemove.reduce((filteredEntity, currentItem) => {

      const {
        [currentItem.id]: removedItem,
        ...remainingEntities
      } = filteredEntity;

      return remainingEntities;
    }, stateEntities);
  }

  /**
   * @param entitiesIds - State entities IDs list
   * @param itemsToRemove - Item to remove from state entities IDs
   *
   * @return Returns an Array of filtered entity IDs list, without the
   *     undesired items.
   * */
  static filterEntitiesIdsList<T extends IdentifiedObject>(
      entitiesIds: string[],
      itemsToRemove: T[],
  ): string[] {

    const shouldKeepId = (entityId: string): boolean => {
      return itemsToRemove.every((itemToRemove) => {

        return itemToRemove.id !== entityId;
      });
    };

    return entitiesIds.filter(shouldKeepId);
  }

  static getDefaultState<T>(): StateModel<T> {
    return {
      entities: {} as T,
      ids: [],
      isLoaded: false,
      hasError: false,
    };
  }

  static getApiLoadSuccessConfig() {
    return {
      isLoaded: true,
      hasError: false,
    };
  }

  static getApiLoadErrorConfig() {
    return {
      isLoaded: false,
      hasError: true,
    };
  }
}

import {
  BaseActionsErrorModel,
} from '../shared/store/base-actions-error.model';
import { ParticipantsStateModel } from './participants.state';
import { ParticipantModel } from './participant.model';
import { FormGroup } from '@angular/forms';
import { RsvpForm } from '../shared/components/rsvp-form/rsvp-form.component';

export namespace ParticipantsActions {

  export class Create {
    static readonly type = '[Participants] Create';

    constructor(public payload: FormGroup<RsvpForm>) {
    }
  }

  export class CreateSuccess {
    static readonly type = '[Participants] CreateSuccess';

    constructor(public payload: ParticipantModel) {
    }
  }

  export class CreateFailed extends BaseActionsErrorModel<{
    errors: { [id in 'phone']: string[] }
  }> {
    static readonly type = '[Participants] CreateFailed';
  }

  export namespace Form {

    export class Submit {
      static readonly type = '[Participants] Form Submit';

      constructor(public payload: FormGroup<RsvpForm>) {
      }
    }
  }

  export namespace Search {

    export class Term {
      static readonly type = '[Participants] Search Term';

      constructor(public payload: string) {
      }
    }

    export class Reset {
      static readonly type = '[Participants] Search Reset';
    }
  }

  export namespace Editing {

    export class Set {
      static readonly type = '[Participants] Registering Set';

      constructor(public payload: ParticipantModel) {
      }
    }

    export class Reset {
      static readonly type = '[Participants] Registering Reset';
    }
  }

  export class Register {
    static readonly type = '[Participants] Register';

    constructor(public payload: FormGroup<RsvpForm>) {
    }
  }

  export class RegisterSuccess {
    static readonly type = '[Participants] RegisterSuccess';

    constructor(public payload: ParticipantModel) {
    }
  }

  export class RegisterFailed extends BaseActionsErrorModel<{
    errors: { [id in 'phone']: string[] }
  }> {
    static readonly type = '[Participants] RegisterFailed';
  }

  export class Get {
    static readonly type = '[Participants] Get';
  }

  export class GetSuccess {
    static readonly type = '[Participants] GetSuccess';

    constructor(public payload: ParticipantsStateModel) {
    }
  }

  export class GetFailed extends BaseActionsErrorModel<string> {
    static readonly type = '[Participants] GetFailed';
  }

  export class ResetLoadedState {
    static readonly type = '[Participants] Reset Loaded State';
  }

  export class ResetState {
    static readonly type = '[Participants] Reset State';
  }
}

import { inject, Injectable } from '@angular/core';
import { SurveyPayloadModel } from '../../survey/survey-payload.model';
import { RsvpPayloadModel } from '../../rsvp/models/rsvp-payload.model';
import { PreferencesService } from './preferences.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  ParticipantResponseModel,
} from '../../participants/participant-response.model';
import { ParticipantsService } from '../../participants/participants.service';
import { ApiService } from './api.service';
import { SurveyService } from '../../survey/survey.service';
import { ToastService } from './toast.service';
import { Device } from '@capacitor/device';
import { environment } from '../../../environments/environment';

export interface SyncParticipantUpdate {
  id: string,
  payload: RsvpPayloadModel
}

export interface SyncData {
  participants: {
    toCreate: RsvpPayloadModel[],
    toUpdate: SyncParticipantUpdate[],
  },
  surveys: SurveyPayloadModel[]
}

const defaultSyncData: SyncData = {
  participants: {
    toCreate: [],
    toUpdate: [],
  },
  surveys: [],
};

@Injectable({
  providedIn: 'root',
})
export class SyncService {

  static apiLogPath = 'log';

  private _apiService = inject(ApiService);
  private _preferencesService = inject(PreferencesService);
  private _toastService = inject(ToastService);

  private _preferencesKey = 'syncData';
  private _dataToSync: SyncData;
  private _syncing = false;

  constructor() {
    this._preferencesService
        .get(this._preferencesKey)
        .subscribe({
          next: (data: SyncData) => {
            console.log('Sync data loaded');
            this._dataToSync = data || defaultSyncData;
          },
        });
  }

  setParticipantToCreate(payload: RsvpPayloadModel): Observable<ParticipantResponseModel> {
    this._dataToSync.participants.toCreate = [
      ...this._dataToSync.participants.toCreate,
      payload,
    ];
    return this._updateSyncDataInPreferences()
               .pipe(
                   map(() => {
                     return {
                       id: Date.now(),
                       ...payload,
                     } as ParticipantResponseModel;
                   }),
               );
  }

  setParticipantToUpdate({
    id,
    payload,
  }: SyncParticipantUpdate): Observable<ParticipantResponseModel> {
    this._dataToSync.participants.toUpdate = [
      ...this._dataToSync.participants.toUpdate,
      { id, payload },
    ];
    return this._updateSyncDataInPreferences()
               .pipe(
                   map(() => {
                     return {
                       id: Number(id),
                       ...payload,
                     } as ParticipantResponseModel;
                   }),
               );
  }

  setSurveyToSync(survey: SurveyPayloadModel): Observable<void> {
    this._dataToSync.surveys = [
      ...this._dataToSync.surveys,
      survey,
    ];
    return this._updateSyncDataInPreferences();
  }

  isSyncing(): Observable<boolean> {
    return of(this._syncing);
  }

  private async _syncParticipantsToCreate() {

    let aux: RsvpPayloadModel[] = [];

    await this._dataToSync
              .participants
              .toCreate
              .reduce(async (previousPromise, payload) => {

                await previousPromise;

                return new Promise((
                    resolve,
                    reject,
                ) => {
                  this._apiService
                      .post<ParticipantResponseModel>(
                          ParticipantsService.apiPath,
                          payload,
                          true,
                      )
                      .subscribe({
                        next: () => resolve(),
                        error: (error) => {
                          // save to retry later
                          aux.push(payload);
                          resolve();
                        },
                      });
                });
              }, Promise.resolve());

    this._dataToSync.participants.toCreate = aux;
  }

  private async _syncParticipantsToUpdate() {

    let aux: SyncParticipantUpdate[] = [];

    await this._dataToSync
              .participants
              .toUpdate
              .reduce(async (previousPromise, payload) => {
                await previousPromise;
                return new Promise((resolve, reject) => {

                  this._apiService
                      .put<ParticipantResponseModel>(
                          `${ ParticipantsService.apiPath }/${ payload.id }`,
                          payload.payload,
                          true,
                      )
                      .subscribe({
                        next: () => resolve(),
                        error: (error) => {
                          // save to retry later
                          aux.push(payload);
                          resolve();
                        },
                      });
                });
              }, Promise.resolve()); // Initial promise

    this._dataToSync.participants.toUpdate = aux;
  }

  private async _syncSurveys() {

    let aux: SurveyPayloadModel[] = [];

    await this._dataToSync
              .surveys
              .reduce(async (previousPromise, payload) => {
                await previousPromise;
                return new Promise((resolve, reject) => {
                  this._apiService
                      .post(
                          SurveyService.apiPath,
                          payload,
                          true,
                      )
                      .subscribe({
                        next: () => resolve(),
                        error: (error) => {
                          // save to retry later
                          aux.push(payload);
                          resolve();
                        },
                      });
                });
              }, Promise.resolve()); // Initial promise

    this._dataToSync.surveys = aux;
  }

  private async _sync() {

    await this._toastService
              .present({ message: 'Sincronização iniciada!' });

    await this._logRemainingSyncDataToServer('início');

    await Promise
        .all([
          await this._syncParticipantsToCreate(),
          await this._syncParticipantsToUpdate(),
          await this._syncSurveys(),
        ]);

    await this._toastService
              .present({
                message: 'Sincronização concluída!',
                duration: ToastService.Duration.LONG,
              });

    this._updateSyncDataInPreferences()
        .subscribe({
          next: () => {
            console.log('Sync data updated');
            this._logRemainingSyncDataToServer('fim');
          },
          complete: () => {
            this._syncing = false;
            console.log('Sync data completed');
          },
        });
  }

  async startSync() {
    if (this._syncing) {
      return;
    }

    this._syncing = true;

    try {
      await this._sync();
    } catch (error) {
      this._syncing = false;
      await this._toastService
                .present({
                  message: 'Ocorreu um erro na sincronização!',
                  duration: ToastService.Duration.LONG,
                });
    }
  }

  private _updateSyncDataInPreferences() {
    return this._preferencesService.set(this._preferencesKey, this._dataToSync);
  }

  private async _logRemainingSyncDataToServer(label: 'início' | 'fim') {

    const deviceId = await Device.getId();

    const payload = {
      version: environment.appVersion,
      label: `${ label } da sincronização`,
      deviceId: deviceId.identifier,
      data: this._dataToSync,
    };

    this._apiService
        .post(SyncService.apiLogPath, payload, true)
        .subscribe({
          next: () => {
            console.log(`Sync data logged ${ label } on server`);
          },
        });
  }
}

import config from '../../capacitor.config';

export class Environment {

  production: boolean;
  apiBaseUrl: string;
  appVersion = '0.0.17';
  appId = config.appId;

  constructor(options: {
    production: boolean,
    apiBaseUrl: string,
    name?: string
  }) {
    this.production = options.production;
    this.apiBaseUrl = `${ options.apiBaseUrl }/api`;
    this.setEnvironmentName(options.name);
  }

  setEnvironmentName(name = 'dev') {

    if (this.production) {
      return;
    }

    this.appVersion = `${ this.appVersion }-${ name }`;
  }
}

import { Injectable } from '@angular/core';
import { Preferences } from '@capacitor/preferences';
import { environment } from '../../../environments/environment';
import { defer, first, from, Observable, of } from 'rxjs';
import { catchError, filter, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PreferencesService {

  private _isPreferencesSetup = false;

  configurePreferences(group = environment.appId): Observable<boolean> {

    if (this._isPreferencesSetup) {
      return of(true);
    }

    return defer(() => {
      return from<Promise<boolean>>(
          Preferences.configure({ group })
                     .then(() => {
                       this._isPreferencesSetup = true;
                       return this._isPreferencesSetup;
                     }))
          .pipe(
              filter((isSetup) => isSetup),
              first(),
          );
    });
  }

  get<T = any>(key: string): Observable<any> {

    return this.configurePreferences()
               .pipe(
                   switchMap(() => {
                     return defer(() => {
                       return from(
                           Preferences.get({ key })
                                      .then(({ value }) => {

                                        if (!!value) {
                                          return JSON.parse(value);
                                        }
                                      }));
                     });
                   }),
                   first(),
                   catchError((error) => {
                     console.warn(error);
                     return this.remove(key);
                   }),
               );
  }

  set(key: string, value: any): Observable<void> {

    return this.configurePreferences()
               .pipe(
                   switchMap(() => {
                     return defer(() => {
                       return from<Promise<void>>(Preferences.set({
                         key,
                         value: JSON.stringify(value),
                       }));
                     });
                   }),
                   first(),
               );
  }

  remove(key: string): Observable<void> {

    return this.configurePreferences()
               .pipe(
                   switchMap(() => {
                     return defer(() => {
                       return from<Promise<void>>(Preferences.remove({ key }));
                     });
                   }),
                   first(),
               );
  }

  clear(): Observable<void> {

    return this.configurePreferences()
               .pipe(
                   switchMap(() => {
                     return defer(() => {
                       return from<Promise<void>>(Preferences.clear());
                     });
                   }),
                   first(),
               );
  }
}

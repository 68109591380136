import {
  BaseActionsErrorModel,
} from '../shared/store/base-actions-error.model';
import { OperationsStateModel } from './operations.state';

export namespace OperationsActions {

  export class Get {
    static readonly type = '[Operations] Get';
  }

  export class GetSuccess {
    static readonly type = '[Operations] GetSuccess';

    constructor(public payload: OperationsStateModel) {
    }
  }

  export class GetFailed extends BaseActionsErrorModel<string> {
    static readonly type = '[Operations] GetFailed';
  }

  export class ResetLoadedState {
    static readonly type = '[Operations] Reset Loaded State';
  }

  export class ResetState {
    static readonly type = '[Operations] Reset State';
  }
}

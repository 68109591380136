import { inject, Injectable } from '@angular/core';
import { ApiService } from '../shared/services/api.service';
import { catchError, switchMap } from 'rxjs/operators';
import { FormGroup } from '@angular/forms';
import { SurveyForm } from './survey.page';
import { SurveyPayloadModel } from './survey-payload.model';
import { NetworkService } from '../shared/services/network.service';
import { SyncService } from '../shared/services/sync.service';

@Injectable()
export class SurveyService {

  static apiPath = 'quizzes';

  private _apiService = inject(ApiService);
  private _networkService = inject(NetworkService);
  private _syncService = inject(SyncService);

  constructor() {
  }

  sendSurvey$(formGroup: FormGroup<SurveyForm>) {

    const payload = new SurveyPayloadModel(formGroup);

    const post$ = this._apiService
                      .post(SurveyService.apiPath, payload, true);
    const addSurveyToSync$ = this._syncService.setSurveyToSync(payload);

    return this._networkService
               .getStatusFromState()
               .pipe(
                   switchMap((status) => {
                     if (status.connected) {
                       return post$;
                     }
                     return addSurveyToSync$;
                   }),
                   catchError((error) => {
                     console.log('error', error);
                     throw error;
                   }),
               );
  }
}

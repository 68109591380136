import { inject, Injectable } from '@angular/core';
import {
  DomSanitizer,
  SafeHtml,
  SafeResourceUrl,
} from '@angular/platform-browser';

@Injectable()
export class DomSanitizerService {

  private _domSanitizer = inject(DomSanitizer);

  trustHtml(html: string): SafeHtml {
    return this._domSanitizer.bypassSecurityTrustHtml(html);
  }

  trustResourceUrl(resourceUrl: string): SafeResourceUrl {
    return this._domSanitizer.bypassSecurityTrustResourceUrl(resourceUrl);
  }
}

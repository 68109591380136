import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { components } from './components';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MockingService } from './services/mocking.service';
import { DomSanitizerService } from './services/dom-sanitizer.service';
import { directives } from './directives';
import { MaskitoDirective } from '@maskito/angular';

@NgModule({
  declarations: [
    ...components,
    ...directives,
  ],
  providers: [MockingService, DomSanitizerService],
  imports: [
    CommonModule,
    IonicModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    MaskitoDirective,
  ],
  exports: [
    CommonModule,
    IonicModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    ...directives,
    ...components,
  ],
})
export class SharedModule {}

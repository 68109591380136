import { inject, Injectable } from '@angular/core';
import { Route, Router } from '@angular/router';

export enum RoutePath {
  HOME = 'home',
  RSVP = 'rsvp',
  REGISTER = 'credenciamento',
  FEEDBACK = 'feedback',
  SURVEY = 'pesquisa',
}

export type RouteName = 'home' | 'rsvp' | 'register' | 'feedback' | 'survey';

export type RouteEntity = {
  [key in RouteName]: Route;
};

@Injectable({ providedIn: 'root' })
export class RoutingService {

  private _router = inject(Router);

  readonly routes: RouteEntity = {
    home: {
      path: RoutePath.HOME,
      title: 'Início',
    },
    rsvp: {
      path: RoutePath.RSVP,
      title: 'RSVP',
    },
    register: {
      path: RoutePath.REGISTER,
      title: 'Credenciamento',
    },
    feedback: {
      path: RoutePath.FEEDBACK,
      title: 'Feedback',
    },
    survey: {
      path: RoutePath.SURVEY,
      title: 'Pesquisa',
    },
  };

  isRsvp() {
    return this._isRoute(RoutePath.RSVP);
  }

  goToRsVp() {
    return this._goTo(`/${ RoutePath.RSVP }`);
  }

  isRegister() {
    return this._isRoute(RoutePath.REGISTER);
  }

  goToRegister() {
    return this._goTo(`/${ RoutePath.REGISTER }`);
  }

  isSurvey() {
    return this._isRoute(RoutePath.SURVEY);
  }

  goToSurvey() {
    return this._goTo(`/${ RoutePath.SURVEY }`);
  }

  private _goTo(route: string) {
    return this._router.navigate([route]);
  }

  private _isRoute(route: RoutePath) {
    return window.location.pathname.includes(route);
  }
}

import { inject, Injectable } from '@angular/core';
import { ToastOptions } from '@ionic/core';
import { ToastController } from '@ionic/angular';

@Injectable({ providedIn: 'root' })
export class ToastService {

  private _toastController = inject(ToastController);

  static Duration = {
    SHORT: 2000,
    DEFAULT: 4000,
    LONG: 6000,
  };

  present(options?: ToastOptions): Promise<void> {

    return this._toastController
               .create({
                 translucent: true,
                 duration: ToastService.Duration.DEFAULT,
                 ...options,
               })
               .then((toast: HTMLIonToastElement) =>
                   toast.present());
  }
}

import { OperationResponseModel } from './operation-response.model';

export class OperationModel {
  id: number;
  isActive: boolean;
  name: string;
  state: string;
  city: string;
  sort: any;

  constructor(operationResponse: OperationResponseModel) {
    this.id = operationResponse.id;
    this.isActive = operationResponse.is_active;
    this.name = operationResponse.name;
    this.state = operationResponse.state;
    this.city = operationResponse.city;
    this.sort = operationResponse.sort;
  }
}

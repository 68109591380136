import { inject, NgModule } from '@angular/core';
import {
  CanMatchFn,
  PreloadAllModules,
  RouterModule,
  Routes,
} from '@angular/router';
import { RoutePath } from './shared/services/routing.service';
import { ParticipantsGuard } from './participants/participants.guard';
import { OperationsGuard } from './operations/operations.guard';

const canMatchParticipantsFn: CanMatchFn =
    () => inject(ParticipantsGuard).canMatch();
const canMatchOperationsFn: CanMatchFn =
    () => inject(OperationsGuard).canMatch();

const routes: Routes = [
  {
    path: '',
    redirectTo: RoutePath.HOME,
    pathMatch: 'full',
  },
  {
    path: RoutePath.HOME,
    canMatch: [canMatchParticipantsFn, canMatchOperationsFn],
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule),
  },
  {
    path: RoutePath.RSVP,
    canMatch: [canMatchParticipantsFn, canMatchOperationsFn],
    loadChildren: () => import('./rsvp/rsvp.module').then(m => m.RsvpPageModule),
  },
  {
    path: RoutePath.REGISTER,
    canMatch: [canMatchParticipantsFn, canMatchOperationsFn],
    loadChildren: () => import('./register/register.module').then(m => m.RegisterPageModule),
  },
  {
    path: RoutePath.SURVEY,
    canMatch: [canMatchOperationsFn],
    loadChildren: () => import('./survey/survey.module').then(m => m.SurveyPageModule),
  },
  {
    path: '**',
    redirectTo: RoutePath.HOME,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  providers: [ParticipantsGuard, OperationsGuard],
  exports: [RouterModule],
})
export class AppRoutingModule {}

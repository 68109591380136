import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { environment } from '../environments/environment';
import { AppState } from './app.state';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsModule } from '@ngxs/store';
import { SharedModule } from './shared/shared.module';
import { ParticipantsService } from './participants/participants.service';
import { ParticipantsState } from './participants/participants.state';
import { HttpClientModule } from '@angular/common/http';
import { httpInterceptors } from './shared/http-interceptors';
import { OperationsService } from './operations/operations.service';
import { OperationsState } from './operations/operations.state';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    SharedModule,
    NgxsModule.forRoot([AppState, OperationsState, ParticipantsState]),
    NgxsFormPluginModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot({
      name: environment.appId,
      disabled: environment.production,
    }),
  ],
  providers: [
    httpInterceptors,
    ParticipantsService,
    OperationsService,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map, switchMap } from 'rxjs/operators';
import { defer, from, Observable, of } from 'rxjs';
import { ApiResponseModel } from '../models/api-response.model';
import {
  ApiErrorDetail,
  ApiErrorResponseModel,
} from '../models/api-error-response.model';
import { PreferencesService } from './preferences.service';
import { AppActions } from '../../app.actions';
import { Store } from '@ngxs/store';
import { ConnectionStatus, Network } from '@capacitor/network';

@Injectable({
  providedIn: 'root',
})
export class ApiService {

  private _httpClient = inject(HttpClient);
  private _preferencesService = inject(PreferencesService);
  private _store = inject(Store);

  /**
   * offline handling
   * Maybe I should save the request in a queue and try to send it again later.
   * */

  get<T>(url: string, skipCache = false): Observable<T> {

    const get$ = this._httpClient
                     .get<ApiResponseModel>(url)
                     .pipe(
                         map((response: ApiResponseModel) => this._getResponseData(
                             response)),
                         switchMap((data: T) => this._saveResponseInPreferences(
                             url,
                             data,
                             skipCache,
                         )),
                         catchError((errorResponse: ApiErrorResponseModel) => {
                           throw this._errorHandler({
                             method: 'GET',
                             errorResponse,
                           });
                         }),
                     );

    return this._checkWhereToRequest({ url: url, request: get$ });
  }

  post<T>(url: string, body: any, skipCache = false): Observable<T> {

    const post$ = this._httpClient
                      .post<ApiResponseModel>(url, body)
                      .pipe(
                          map((response: ApiResponseModel) => this._getResponseData(
                              response)),
                          switchMap((data: T) => this._saveResponseInPreferences(
                              url,
                              data,
                              skipCache,
                          )),
                          catchError((errorResponse: ApiErrorResponseModel) => {
                            throw this._errorHandler({
                              method: 'POST',
                              errorResponse,
                            });
                          }),
                      );

    return this._checkWhereToRequest({ url: url, request: post$ });
  }

  put<T>(url: string, body: any, skipCache = false): Observable<T> {

    const put$ = this._httpClient
                     .put<ApiResponseModel>(url, body)
                     .pipe(
                         map((response: ApiResponseModel) => this._getResponseData(
                             response)),
                         switchMap((data: T) => this._saveResponseInPreferences(
                             url,
                             data,
                             skipCache,
                         )),
                         catchError((errorResponse: ApiErrorResponseModel) => {
                           throw this._errorHandler({
                             method: 'PUT',
                             errorResponse,
                           });
                         }),
                     );

    return this._checkWhereToRequest({ url: url, request: put$ });
  }

  delete<T>(url: string, body?: any, skipCache = false): Observable<T> {

    const delete$ = this._httpClient
                        .delete<ApiResponseModel>(url, { body })
                        .pipe(
                            map((response: ApiResponseModel) => this._getResponseData(
                                response)),
                            switchMap((data: T) => this._saveResponseInPreferences(
                                url,
                                data,
                                skipCache,
                            )),
                            catchError((errorResponse: ApiErrorResponseModel) => {
                              throw this._errorHandler({
                                method: 'DELETE',
                                errorResponse,
                              });
                            }),
                        );

    return this._checkWhereToRequest({ url: url, request: delete$ });
  }

  private _checkWhereToRequest<T>({ url, request }: {
    url: string,
    request: Observable<T>
  }): Observable<T> {

    return defer(() => {
      return from<Promise<ConnectionStatus>>(
          Network.getStatus(),
      )
          .pipe(
              switchMap((status) => {

                if (status.connected) {
                  return request;
                }

                return this._getResponseFromPreferences<T>(url);
              }),
          );
    });
  }

  private _getResponseData(response: ApiResponseModel | any): any {
    if (!response) {
      throw new Error('API response is empty');
    }

    if (!response.data) {
      return response;
    }

    return response.data;
  }

  private _getResponseFromPreferences<T>(
      url: string,
  ): Observable<T> {
    return this._preferencesService
               .get<T>(url)
               .pipe(
                   map((response: T) => {
                     if (!response) {
                       const message = `Endpoint ${ url } response is empty`;
                       this._store.dispatch(new AppActions.NeedsFirstRunOnline());
                       console.warn(message);
                       throw new Error(message);
                     }
                     return response;
                   }),
               );
  }

  private _saveResponseInPreferences<T>(
      url: string,
      responseData: T,
      skipCache = false,
  ): Observable<T> {

    if (skipCache) {
      return of(responseData);
    }

    return this._preferencesService
               .set(url, responseData)
               .pipe(
                   map(() => responseData),
               );
  }

  private _errorHandler({
    method,
    errorResponse,
  }: {
    method: string,
    errorResponse: ApiErrorResponseModel
  }): ApiErrorDetail {

    console.log(`API ${ method } Error:`, errorResponse);

    const unknownErrorMessage = 'Ocorreu um erro inesperado, por favor, tente mais tarde';
    const isUnknownError = errorResponse.statusText === 'Unknown Error';

    if (isUnknownError) {
      return { message: unknownErrorMessage };
    }

    const hasSpecifiedError = !!Object.keys(errorResponse.error).length;

    if (hasSpecifiedError) {
      return errorResponse.error;
    }

    return {
      message: errorResponse.message || unknownErrorMessage,
    };
  }
}

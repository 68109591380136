import { inject, Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { ParticipantsActions } from './participants.actions';
import {
  Entity,
  StateHelpers,
  StateModel,
} from '../shared/store/state-helpers';
import { ParticipantModel } from './participant.model';
import { StoreActions } from '../shared/store/store.actions';
import { ParticipantsService } from './participants.service';
import { StringHelpers } from '../shared/helpers/string.helpers';
import { RsvpPayloadModel } from '../rsvp/models/rsvp-payload.model';
import { RoutingService } from '../shared/services/routing.service';

export type ParticipantsEntity = Entity<ParticipantModel>;
export type DefaultParticipantsStateModel = StateModel<ParticipantsEntity>;
export type SearchState = {
  term: string;
};

export interface ParticipantsStateModel extends DefaultParticipantsStateModel {
  editingParticipant: ParticipantModel;
  search: SearchState;
}

const PARTICIPANTS_STATE_TOKEN = new StateToken<ParticipantsStateModel>(
    'ParticipantsState');

const defaults: ParticipantsStateModel = {
  ...StateHelpers.getDefaultState(),
  editingParticipant: {} as ParticipantModel,
  search: {
    term: '',
  },
};

@State<ParticipantsStateModel>({
  name: PARTICIPANTS_STATE_TOKEN,
  defaults,
})
@Injectable()
export class ParticipantsState {

  private _participantsService = inject(ParticipantsService);
  private _routingService = inject(RoutingService);

  @Selector()
  static hasError(state: ParticipantsStateModel): boolean {
    return state.hasError;
  }

  @Selector()
  static isLoaded(state: ParticipantsStateModel): boolean {
    return state.isLoaded;
  }

  @Selector()
  static isEditingParticipant(state: ParticipantsStateModel): boolean {
    return !!Object.keys(state.editingParticipant).length;
  }

  @Selector()
  static getEditingParticipant(state: ParticipantsStateModel): ParticipantModel {
    return state.editingParticipant;
  }

  @Selector()
  static getParticipants(state: ParticipantsStateModel): ParticipantModel[] {
    return StateHelpers.mapIdsListToEntitiesList(state.ids, state.entities);
  }

  @Selector()
  static getFoundParticipant(state: ParticipantsStateModel): ParticipantModel[] {
    return StateHelpers.mapIdsListToEntitiesList(state.ids, state.entities)
                       .filter((participant) => {

                         if (!state.search.term) {
                           return false;
                         }

                         const sanitizedSearchTerm =
                             StringHelpers.normalizeString(state.search.term)
                                          .toLowerCase();

                         const sanitizedParticipant =
                             StringHelpers.normalizeString(participant.name)
                                          .toLowerCase();

                         return sanitizedParticipant
                             .includes(sanitizedSearchTerm);
                       });

  }

  @Action(ParticipantsActions.Get)
  getParticipants(ctx: StateContext<ParticipantsStateModel>) {
    return this._participantsService
               .getParticipants$()
               .subscribe({
                 next: (participants) => {

                   const newState = {
                     ...ctx.getState(),
                     entities: StateHelpers.reduceListToEntities(participants),
                     ids: StateHelpers.mapEntitiesListToIdsList(participants),
                     ...StateHelpers.getApiLoadSuccessConfig(),
                   };

                   return ctx.dispatch(new ParticipantsActions.GetSuccess(
                       newState));
                 },
                 error: (error) => {
                   return ctx.dispatch(new ParticipantsActions.GetFailed(error));
                 },
               });
  }

  @Action(ParticipantsActions.GetSuccess)
  getSuccess(
      ctx: StateContext<ParticipantsStateModel>,
      action: ParticipantsActions.GetSuccess,
  ) {
    ctx.patchState(action.payload);
  }

  @Action(ParticipantsActions.GetFailed)
  getFailed(ctx: StateContext<ParticipantsStateModel>) {
    ctx.patchState(StateHelpers.getApiLoadErrorConfig());
  }

  @Action(ParticipantsActions.Form.Submit)
  submitForm(
      ctx: StateContext<ParticipantsStateModel>,
      action: ParticipantsActions.Form.Submit,
  ) {
    const state = ctx.getState();

    if (ParticipantsState.isEditingParticipant(state)) {
      return ctx.dispatch(new ParticipantsActions.Register(action.payload));
    }

    return ctx.dispatch(new ParticipantsActions.Create(action.payload));
  }

  @Action(ParticipantsActions.Create)
  createParticipant(
      ctx: StateContext<ParticipantsStateModel>,
      action: ParticipantsActions.Create,
  ) {

    const shouldSetReservation = this._routingService.isRsvp();
    const shouldSetRegistration = this._routingService.isRegister();

    const payload = new RsvpPayloadModel(
        action.payload,
        shouldSetReservation,
        shouldSetRegistration,
    );

    return this._participantsService
               .createParticipant$(payload)
               .subscribe({
                 next: (participant) => {
                   return ctx.dispatch(
                       new ParticipantsActions.CreateSuccess(participant));
                 },
                 error: (error) => {
                   return ctx.dispatch(
                       new ParticipantsActions.CreateFailed(error));
                 },
               });
  }

  @Action(ParticipantsActions.CreateSuccess)
  createSuccess(
      ctx: StateContext<ParticipantsStateModel>,
      action: ParticipantsActions.CreateSuccess,
  ) {

    ctx.patchState({
      entities: {
        ...ctx.getState().entities,
        [action.payload.id]: action.payload,
      },
      ids: [
        ...ctx.getState().ids,
        action.payload.id,
      ],
    });
  }

  @Action(ParticipantsActions.RegisterSuccess)
  registerSuccess(
      ctx: StateContext<ParticipantsStateModel>,
      action: ParticipantsActions.RegisterSuccess,
  ) {

    ctx.patchState({
      entities: {
        ...ctx.getState().entities,
        [action.payload.id]: action.payload,
      },
    });
  }

  @Action(ParticipantsActions.CreateFailed)
  createFailed(ctx: StateContext<ParticipantsStateModel>) {
    // do something;
  }

  @Action(ParticipantsActions.Editing.Set)
  setEditingParticipant(
      ctx: StateContext<ParticipantsStateModel>,
      action: ParticipantsActions.Editing.Set,
  ) {
    ctx.patchState({
      editingParticipant: action.payload,
    });
  }

  @Action(ParticipantsActions.Editing.Reset)
  resetEditingParticipant(ctx: StateContext<ParticipantsStateModel>) {
    ctx.patchState({
      editingParticipant: defaults.editingParticipant,
    });
  }

  @Action(ParticipantsActions.Register)
  registerParticipant(
      ctx: StateContext<ParticipantsStateModel>,
      action: ParticipantsActions.Register,
  ) {
    const state = ctx.getState();

    const shouldSetReservation = (state.editingParticipant.reserved)
                                 ? true
                                 : this._routingService.isRsvp();

    const shouldSetRegistration = (state.editingParticipant.accredited)
                                  ? true
                                  : this._routingService.isRegister();

    const payload = new RsvpPayloadModel(
        action.payload,
        shouldSetReservation,
        shouldSetRegistration,
    );

    return this._participantsService
               .updateParticipant$(state.editingParticipant.id, payload)
               .subscribe({
                 next: (participant) => {
                   return ctx.dispatch(
                       new ParticipantsActions.RegisterSuccess(participant));
                 },
                 error: (error) => {
                   return ctx.dispatch(
                       new ParticipantsActions.RegisterFailed(error));
                 },
               });
  }

  @Action(ParticipantsActions.CreateFailed)
  registerFailed(ctx: StateContext<ParticipantsStateModel>) {
    // do something;
  }

  @Action(ParticipantsActions.Search.Term)
  searchingParticipants(
      ctx: StateContext<ParticipantsStateModel>,
      action: ParticipantsActions.Search.Term,
  ) {
    ctx.patchState({
      search: {
        term: action.payload,
      },
    });
  }

  @Action(ParticipantsActions.ResetLoadedState)
  resetLoadedState(ctx: StateContext<ParticipantsStateModel>) {
    ctx.patchState({
      isLoaded: defaults.isLoaded,
      hasError: defaults.hasError,
    });
  }

  @Action([
    ParticipantsActions.ResetState,
    StoreActions.ResetStore,
  ])
  resetState(ctx: StateContext<ParticipantsStateModel>) {
    ctx.patchState({
      ...defaults,
    });
  }
}

import { inject, Injectable } from '@angular/core';
import { UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { Select, Store } from '@ngxs/store';
import { OperationsState } from './operations.state';
import { OperationsActions } from './operations.actions';
import { SyncService } from '../shared/services/sync.service';

@Injectable()
export class OperationsGuard {

  private _store = inject(Store);
  private _syncService = inject(SyncService);

  @Select(OperationsState.isLoaded)
  isOperationsLoaded$: Observable<boolean>;

  @Select(OperationsState.hasError)
  hasStateError$: Observable<boolean>;

  canMatch(): Observable<boolean | UrlTree> {
    return this._syncService
               .isSyncing()
               .pipe(
                   filter((isSyncing) => !isSyncing),
                   switchMap(() => this.isOperationsLoaded$),
                   switchMap((isLoaded) => {
                     if (isLoaded) {
                       return of(true);
                     }

                     return this._store.dispatch(new OperationsActions.Get())
                                .pipe(
                                    switchMap(() => this._handleApiError$()),
                                );
                   }),
               );
  }

  private _handleApiError$(): Observable<boolean | UrlTree> {
    return this.hasStateError$
               .pipe(
                   filter((hasError) => hasError),
                   map(() => false),
               );
  }
}

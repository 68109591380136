import {
  AbstractControl,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';

export const namePattern = /\b([a-zA-ZÀ-ÿ][-,A-Za-z. ']{3,} *)+/;

export class CustomValidators {

  static passwordMatchValidator(): ValidatorFn {

    return (formGroup: AbstractControl): ValidationErrors | null => {

      const password = formGroup.get('password');
      const passwordConfirm = formGroup.get('passwordConfirm');

      const hasPasswordControls = password && passwordConfirm;

      if (!hasPasswordControls) {
        throw new Error('Form MUST have password and passwordConfirm controls');
      }

      const hasPasswordValues = password.value && passwordConfirm.value;

      if (!hasPasswordValues) {
        // Should not show any error message
        return null;
      }

      const isMatching = password.value === passwordConfirm.value;

      return isMatching ? null : { passwordMismatch: true };
    };
  }

  static MatchValidator(source: string, target: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const sourceCtrl = control.get(source);
      const targetCtrl = control.get(target);

      return sourceCtrl && targetCtrl && sourceCtrl.value !== targetCtrl.value
             ? { mismatch: true }
             : null;
    };
  }

  static PasswordStrengthValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {

      const value = control.value;

      if (!value) {
        return null;
      }

      // should have minimum 8 characters
      // should have at least one upper case letter
      // should have at least one lower case letter
      // should have at least one numeric value
      // should have at least one special character
      const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$!%*?&])[A-Za-z\d@#$!%*?&]{8,}$/;

      const passwordValid = passwordPattern.test(value);

      return !passwordValid ? { passwordStrength: true } : null;
    };
  }

  static getOnlyNumbers(value: string) {
    return value.replace(/[^\d]+/g, '');
  }

  static Phone(): ValidatorFn {

    return (field: AbstractControl): ValidationErrors | null => {

      const minLength = 10; // phones XX 99999-9999 e XX 9999-9999
      const valueWithOutMask = this.getOnlyNumbers(field.value);
      const unmaskedValue = valueWithOutMask.length;

      if (!valueWithOutMask || unmaskedValue < minLength) {
        return { hasMinLength: 'O número está incompleto.' };
      }

      const ddds = [
        '11',
        '12',
        '13',
        '14',
        '15',
        '16',
        '17',
        '18',
        '19',
        '21',
        '22',
        '24',
        '27',
        '28',
        '31',
        '32',
        '33',
        '34',
        '35',
        '37',
        '38',
        '41',
        '42',
        '43',
        '44',
        '45',
        '46',
        '47',
        '48',
        '49',
        '51',
        '53',
        '54',
        '55',
        '61',
        '62',
        '63',
        '64',
        '65',
        '66',
        '67',
        '68',
        '69',
        '71',
        '73',
        '74',
        '75',
        '77',
        '79',
        '81',
        '82',
        '83',
        '84',
        '85',
        '86',
        '87',
        '88',
        '89',
        '91',
        '92',
        '93',
        '94',
        '95',
        '96',
        '97',
        '98',
        '99',
      ];
      const ddd = valueWithOutMask.substring(2, 0);
      const hasDDDValid = ddds.some(currentDDD => currentDDD === ddd);
      const invalidFeedback = 'Celular inválido';

      if (!hasDDDValid) {
        return { hasDDDValid: invalidFeedback };
      }

      // const isCellPhone = valueWithOutMask.length === minLength;
      //
      // if (isCellPhone) {
      //
      //   const brazilianPhonePrefix = '9';
      //   const hasPhoneValidPrefix = valueWithOutMask.substring(
      //       2,
      //       3,
      //   ) === brazilianPhonePrefix;
      //
      //   return hasPhoneValidPrefix
      //          ? null
      //          : { hasPhoneValidPrefix: invalidFeedback };
      // }

      return null;
    };
  }
}

<form [formGroup]="searchFormGroup">
  <app-form-section>
    <div class="ion-margin-bottom">
      <ion-grid>
        <ion-row class="ion-align-items-end">
          <ion-col>
            <ion-label>
              Nome completo
            </ion-label>
            <ion-input formControlName="searchTerm"
                       clearInput="true"
                       debounce="500"
                       name="searchTerm"
                       class="custom"
                       inputmode="text"
                       aria-label="Pesquise pelo nome do participante"
                       placeholder="Pesquise pelo nome do participante">
            </ion-input>
          </ion-col>
          <ion-col size="12"
                   size-sm="auto">
            <ion-button expand="block"
                        class="ion-no-margin">
              pesquisar
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>

    <ng-container *ngIf="shouldShowSearchResults()">
      <ng-container *ngIf="getFoundParticipants$ | async as foundParticipants">
        <ng-container *ngIf="!!foundParticipants.length; else notFoundParticipantsBlock">
          <div class="ion-padding-vertical results">
            <ion-button *ngFor="let participant of foundParticipants; trackBy: trackByFn"
                        [disabled]="shouldDisableResultParticipant(participant)"
                        (click)="editParticipant(participant)"
                        class="results-item ion-no-margin"
                        fill="light"
                        expand="block">
              <div class="w-100 text-transform-initial ion-text-start fw-semi-bold">
                <ion-text color="primary"
                          class="results-line fs-normal">
                  {{ participant.name }}
                </ion-text>
                <ion-text color="dark"
                          class="results-line">
                  {{ participant.phone }}
                </ion-text>
                <ion-text color="dark"
                          class="results-line">
                  {{ participant.cityState }}
                </ion-text>
                <ng-container *ngIf="participant.reserved">
                  <ion-text color="dark"
                            class="results-line">
                    RSVP - Confirmado
                    <ion-icon name="checkmark-circle"
                              color="success"></ion-icon>
                  </ion-text>
                </ng-container>
                <ng-container *ngIf="participant.accredited">
                  <ion-text color="dark"
                            class="results-line">
                    Credenciamento - Confirmado
                    <ion-icon name="checkmark-circle"
                              color="success"></ion-icon>
                  </ion-text>
                </ng-container>

              </div>
            </ion-button>
          </div>
        </ng-container>

        <ng-template #notFoundParticipantsBlock>
          <div *ngIf="!!this.searchFormGroup.controls.searchTerm.value"
               class="ion-margin-bottom">
            <h2 class="fs-normal">
              Cadastro não encontrado
            </h2>
            <ion-text color="dark">
              Infelizmente não encontramos nenhum registro correspondente ao
              nome fornecido ou essa etapa já foi concluída.
              <br>
              Caso desejar clique no botão abaixo e realize o cadastro.
            </ion-text>
            <div class="ion-text-end">
              <ion-button (click)="reset()"
                          color="warning">
                realizar cadastro
              </ion-button>
            </div>
          </div>
        </ng-template>
      </ng-container>
    </ng-container>
  </app-form-section>
</form>

<form #rsvpForm
      [formGroup]="rsvpFormGroup"
      (submit)="onSubmit()">

  <app-form-section *ngIf="(operations$ | async) as operations"
                    title="Escolha sua praça">
    <ion-label>
      Praça
    </ion-label>
    <ion-select aria-label="Selecione sua praça"
                formControlName="operation"
                interface="popover"
                label-placement="floating"
                placeholder="Selecione sua praça">
      <ion-select-option *ngFor="let operation of operations"
                         [value]="operation.id">
        {{ operation.name }}
      </ion-select-option>
    </ion-select>
  </app-form-section>

  <app-form-section title="Informações pessoais">
    <div class="ion-margin-bottom">
      <ion-label>
        Nome completo
      </ion-label>
      <ion-input class="custom"
                 formControlName="name"
                 inputmode="text"
                 aria-label="Digite seu nome completo"
                 placeholder="Insira seu nome">
        <ion-label *ngIf="rsvpFormGroup.controls.name.invalid"
                   class="append-item">
          Obrigatório
        </ion-label>
      </ion-input>
    </div>
    <div class="ion-margin-bottom">
      <ion-label>
        Cidade
      </ion-label>
      <ion-input class="custom"
                 formControlName="city"
                 inputmode="text"
                 aria-label="Cidade"
                 placeholder="Digite a cidade de onde você veio">
        <ion-label *ngIf="rsvpFormGroup.controls.city.invalid"
                   class="append-item">
          Obrigatório
        </ion-label>
      </ion-input>
    </div>

    <div class="ion-margin-bottom">
      <ion-label>
        Telefone
      </ion-label>
      <ion-input [maskitoElement]="maskPredicate"
                 [maskito]="maskitoCellphone"
                 aria-label="Telefone"
                 class="custom"
                 formControlName="phone"
                 inputmode="tel"
                 placeholder="(00) 00000-0000"
                 type="tel">
        <ng-container *ngIf="rsvpFormGroup.controls.phone.invalid && rsvpFormGroup.controls.phone.touched">
          <ion-label *ngIf="rsvpFormGroup.controls.phone.hasError('required')"
                     class="append-item">
            Obrigatório
          </ion-label>
          <ion-label *ngIf="!rsvpFormGroup.controls.phone.hasError('required')"
                     class="append-item">
            Por favor, insira um celular válido
          </ion-label>
        </ng-container>
      </ion-input>
    </div>
    <div class="ion-margin-bottom">
      <ion-label>
        Profissão
      </ion-label>
      <ion-input class="custom"
                 formControlName="profession"
                 inputmode="text"
                 aria-label="Profissão"
                 placeholder="Digite sua profissão">
        <ion-label *ngIf="rsvpFormGroup.controls.profession.invalid"
                   class="append-item">
          Obrigatório
        </ion-label>
      </ion-input>
    </div>

    <div class="ion-margin-bottom">
      <ion-label>
        Empresa onde trabalho
      </ion-label>
      <ion-input class="custom"
                 formControlName="company"
                 inputmode="text"
                 aria-label="Cidade"
                 placeholder="Digite o nome da empresa">
      </ion-input>
    </div>

    <div class="ion-margin-bottom">
      <ion-label>
        Tamanho da propriedade
      </ion-label>
      <ion-input class="custom"
                 formControlName="propertySize"
                 inputmode="text"
                 aria-label="Tamanho da propriedade"
                 placeholder="Digite o tamanho da sua propriedade em hectares">
        <ion-label *ngIf="rsvpFormGroup.controls.propertySize.invalid"
                   class="append-item">
          Obrigatório
        </ion-label>
      </ion-input>
    </div>
  </app-form-section>

  <app-form-section title="Informações gerais">
    <ion-label>
      Qual nome você quer no seu crachá?
    </ion-label>
    <ion-input class="custom"
               formControlName="badgeName"
               inputmode="text"
               aria-label="Qual nome você quer no seu crachá?"
               placeholder="Nome impresso no crachá">
      <ion-label *ngIf="rsvpFormGroup.controls.badgeName.invalid"
                 class="append-item">
        Obrigatório
      </ion-label>
    </ion-input>
  </app-form-section>

  <app-form-section *ngIf="routingService.isRsvp()"
                    title="Termos de uso e Política de privacidade">
    <ion-checkbox mode="ios"
                  formControlName="privacyPolicy"
                  labelPlacement="end">
      <ion-text class="ion-text-wrap"
                color="dark">
        Autorizo a coleta e armazenamento dos meus dados pessoais para
        registro
        e controle de acesso à atividade organizada pela Bayer. Declaro para
        todos os fins que os dados aqui informados são verdadeiros.
      </ion-text>
    </ion-checkbox>
  </app-form-section>

  <ion-row class="ion-justify-content-between">
    <ion-col size-sm="auto">
      <ion-button class="ion-text-capitalize"
                  color="primary"
                  fill="clear"
                  routerLink="..">
        Voltar
      </ion-button>
    </ion-col>
    <ion-col size-sm="auto">
      <ion-button type="submit"
                  [disabled]="rsvpFormGroup.invalid"
                  expand="block">
        enviar
      </ion-button>
    </ion-col>
  </ion-row>

</form>

<section class="min-vh-100">
  <ion-grid>
    <ion-row class="ion-justify-content-center">
      <ion-col size="11"
               size-xl="8">
        <section class="bg-blur">
          <div class="ion-padding ion-margin-bottom px-1 px-md-0">
            <ion-button class="ion-no-padding"
                        color="primary"
                        fill="clear"
                        routerLink="/">
              <ion-img src="assets/logo.png"
                       class="logo"></ion-img>
            </ion-button>
          </div>
          <h1 class="h3 ion-margin-bottom">
            {{ title }}
          </h1>
          <ng-content></ng-content>
        </section>
      </ion-col>
    </ion-row>
  </ion-grid>
  <div class="bg-animated">
    <ion-img src="assets/img-pin-waves.png"
             class="bg-animated-base-img"></ion-img>
    <svg class="waves"
         xmlns="http://www.w3.org/2000/svg"
         xmlns:xlink="http://www.w3.org/1999/xlink"
         viewBox="0 24 150 28"
         preserveAspectRatio="none"
         shape-rendering="auto">
      <defs>
        <path id="gentle-wave"
              d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"/>
      </defs>
      <g class="parallax">
        <use xlink:href="#gentle-wave"
             x="48"
             y="0"
             fill="rgba(255,146,107,0.7"/>
        <use xlink:href="#gentle-wave"
             x="48"
             y="3"
             fill="rgba(255,125,51,0.5)"/>
        <use xlink:href="#gentle-wave"
             x="48"
             y="5"
             fill="rgba(217,24,20,0.3)"/>
        <use xlink:href="#gentle-wave"
             x="48"
             y="7"
             fill="rgba(217,24,20,0.8)"/>
      </g>
    </svg>
    <ion-img src="assets/img-pin.png"
             class="bg-animated-pin"></ion-img>
  </div>
</section>
<footer class="footer">
  <ion-grid>
    <ion-row class="ion-align-items-center">
      <ion-col size="auto">
        <ion-button href="https://bayeri2x.xlab.app.br/termos-de-uso"
                    target="_blank"
                    fill="clear"
                    color="primary"
                    class="fw-semi-bold text-transform-initial">
          Termos de uso
        </ion-button>
      </ion-col>
      <ion-col size="auto">
        <ion-button href="https://bayeri2x.xlab.app.br/politica-de-privacidade"
                    target="_blank"
                    fill="clear"
                    color="primary"
                    class="fw-semi-bold text-transform-initial">
          Política de privacidade
        </ion-button>
      </ion-col>
      <ion-col class="ms-auto"
               size="auto">
        <app-version></app-version>
      </ion-col>
    </ion-row>
  </ion-grid>
</footer>

export class StringHelpers {

  static slugify(str: string, separator = '-') {
    return StringHelpers.normalizeString(str)
                        .toLowerCase()
                        .trim()
                        .replace(
                            /[^a-z0-9 ]/g,
                            '',
                        )
                        .replace(/\s+/g, separator);
  };

  static normalizeString(str: string): string {
    return str
        .toString()
        .normalize('NFD')
        // remove all chars not letters, numbers and spaces (to be replaced)
        .replace(/\p{Diacritic}/gu, '');
  }
}

import { environment } from '../../../environments/environment';

export class BaseActionsErrorModel<T> {

  constructor(public reason: T) {
    this._printError();
  }

  private _printError() {
    if (environment.production) {
      return;
    }

    const baseErrorMessage = 'Base Actions Error';

    if (this.reason instanceof Error) {
      console.error(`${ baseErrorMessage }: ${ this.reason.message }`);
      return;
    }

    if (typeof this.reason === 'string') {
      console.error(`${ baseErrorMessage }: ${ this.reason }`);
      return;
    }

    console.error(
        `${ baseErrorMessage }:`,
        JSON.stringify(this.reason, null, 2),
    );
  }
}

import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { inject, Injectable } from '@angular/core';
import { filter, finalize, switchMap } from 'rxjs/operators';
import { LoadingIndicatorService } from '../services/loading-indicator.service';

@Injectable()
export class LoadingIndicatorInterceptor implements HttpInterceptor {

  private _loadingIndicatorService = inject(LoadingIndicatorService);

  intercept(
      httpRequest: HttpRequest<any>,
      next: HttpHandler,
  ): Observable<HttpEvent<any>> {

    if (this._shouldSkipLoadingIndicator(httpRequest.url)) {
      return next.handle(httpRequest);
    }

    return this._loadingIndicatorService
               .present()
               .pipe(
                   switchMap(() => this._handleRequest(httpRequest, next)),
               );
  }

  private _shouldSkipLoadingIndicator(url: string): boolean {

    const urlsToSkip = [
      'statistics',
    ];

    return urlsToSkip.some((path) => url.includes(path));
  }

  private _handleRequest(
      httpRequest: HttpRequest<any>,
      next: HttpHandler,
  ) {
    return next.handle(httpRequest)
               .pipe(
                   filter((event) => event instanceof HttpResponse),
                   finalize(() => {
                     this._loadingIndicatorService.dismiss();
                   }),
               );
  }
}

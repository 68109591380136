import { inject, Injectable } from '@angular/core';
import { ApiService } from '../shared/services/api.service';
import { OperationResponseModel } from './operation-response.model';
import { catchError, map } from 'rxjs/operators';
import { OperationModel } from './operation.model';

@Injectable()
export class OperationsService {

  static apiPath = 'cities';

  private _apiService = inject(ApiService);

  constructor() {
  }

  getOperations$() {
    return this._apiService
               .get<OperationResponseModel[]>(OperationsService.apiPath)
               .pipe(
                   map((operationsResponse: OperationResponseModel[]) =>
                       operationsResponse.map((participantResponse) =>
                           new OperationModel(participantResponse)),
                   ),
                   catchError((error) => {
                     console.log('error', error);
                     throw error;
                   }),
               );
  }
}

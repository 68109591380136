import { ParticipantResponseModel } from './participant-response.model';

export class ParticipantModel {
  id: string;
  cityId: number;
  name: string; // 'Nome Junior',
  nickname: string; // 'Nome do crachá',
  cityState: string; // 'Cidade - SP',
  phone: string; // '(17) 3353-2444',
  company: string; // 'FMD',
  profession: string; // 'Desenvolvedor',
  property: string; // '1.000 m²'
  reserved: boolean; // preencheu o RSVP
  accredited: boolean; // retirou o crachá (check-in, credenciamento)

  constructor(participantResponse: ParticipantResponseModel) {
    this.id = String(participantResponse.id);
    this.cityId = participantResponse.city_id;
    this.name = participantResponse.name;
    this.nickname = participantResponse.nickname;
    this.cityState = participantResponse.city_state;
    this.phone = participantResponse.phone;
    this.company = participantResponse.company;
    this.profession = participantResponse.profession;
    this.property = participantResponse.property;
    this.reserved = participantResponse.reserved;
    this.accredited = participantResponse.accredited;
  }
}

import { ConnectionStatus } from '@capacitor/network';

export namespace AppActions {

  export class NetworkStatusChanged {
    static readonly type = '[App] NetworkStatusChanged';

    constructor(public payload: ConnectionStatus) {
    }
  }

  export class NeedsFirstRunOnline {
    static readonly type = '[App] Needs First Run Online';
  }

  export class ResetState {
    static readonly type = '[App] Reset State';
  }
}
